import React, { useState } from "react"
import GoogleMapReact from "google-map-react"

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = props => {
  const [center, setCenter] = useState({ lat: 11.0168, lng: 76.9558 })
  const [zoom, setZoom] = useState(11)

  const mapOptions = {
    styles: [
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
    ],
  }

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        options={mapOptions}
        //   bootstrapURLKeys={{ key: 'AIzaSyDidUSUi9MhaU4wtDhYlaaoXUT5mFrHs6I' }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={11.0168} lng={76.9558} text="My Marker" />
      </GoogleMapReact>
    </div>
  )
}

export default GoogleMap
