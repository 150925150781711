import React from "react"
import Layout from "../components/Layout"

import GoogleMap from "../components/GoogleMap/GoogleMap"

const ServicesPage = ({ location }) => {
  return (
    <Layout location={location}>
      HELLO FROM ServicesPage
      <GoogleMap />
    </Layout>
  )
}

export default ServicesPage
